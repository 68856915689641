import React, { ReactNode } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

export interface TransitionTheme {
  primary: string;
}

export interface WeightTheme {
  regular: string;
  bold: string;
}

export enum Weights {
  REGULAR = '400',
  BOLD = '700',
}

export interface ColorTheme {
  pink: string;
  white: string;
  offWhite: string;
  lightGray: string;
  black: string;
  offBlack: string;
  blue: string;
  green: string;
  offBlue: string;
  paleBlue: string;
  lightGray1: string;
  yellow: string;
  orange: string;
  gray: string;
  gold: string;
  absoluteBlack: string;
}

export enum Colors {
  PINK = '#C72D92',
  WHITE = '#FFFFFF',
  OFF_WHITE = '#DAE2FB',
  LIGHT_GRAY = '#F5F5F5',
  ABSOLUTE_BLACK = '#0A0C13',
  BLACK = '#171A22',
  OFF_BLACK = '#626366',
  BLUE = '#486DD3',
  GREEN = '#00A500',
  LIGHT_GRAY_1 = '#f8f8f8',
  PALE_BLUE = '#CADDFF',
  YELLOW = '#F9D624',
  OFF_BLUE = '#486DD3',
  ORANGE = '#F99F04',
  GRAY = '#798094',
  GOLD = '#F1A819',
}

export interface FontSizesTheme {
  defaultSize: number;

  // from 0 to 20
  s1: number;
  s2: number;
  s3: number;
  s4: number;

  // from 21 to 40
  m1: number;
  m2: number;
  m3: number;
}

export interface LineHeightsTheme {
  // from 0 to 20
  s1: number;
  s2: number;
  s3: number;

  // from 21 to 40
  m1: number;
  m2: number;
  m3: number;
  m4: number;

  // from 41
  l1: number;
}

export interface BreakpointsTheme {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
  xxl: number;
  arrowVisibilityHeight: number;
}

export interface SpacingTheme {
  // from 0 to 20
  s1: number;
  // from 20to 40
  m1: number;
  m2: number;
  m3: number;
  // from 40 to 60
  l1: number;
  l2: number;
  // from 60 to 120 xl
  xl1: number;
  xl2: number;
  // from 120 and more
  xxl1: number;
  xxl2: number;
  xxl3: number;
}

export interface BorderRadiusTheme {
  xss: number;
  xs: number;
  sm: number;
  md: number;
  xxl: number;
}

export interface HeaderSize {
  paddingPrimary: number;
  paddingTopMobile: number;
  paddingBottomMobile: number;
  heightDesktop: number;
  heightMobile: number;
}

export interface BorderWidth {
  sm: number;
}
export interface ButtonSize {
  // h - height, w - width, hm = height mobile, wm - width mobile
  h1: number;
  w1: number;
  hm1: number;
  wm1: number;

  h2: number;
  w2: number;
  hm2: number;
  wm2: number;

  h3: number;
  w3: number;
  hm3: number;
  wm3: number;
}
export interface LogoSize {
  h: number;
  w: number;
}
export interface CodeTheme {
  colors: ColorTheme;
  transition: TransitionTheme;
  weights: WeightTheme;
  fontSize: FontSizesTheme;
  lineHeight: LineHeightsTheme;
  breakpoints: BreakpointsTheme;
  spacing: SpacingTheme;
  borderRadius: BorderRadiusTheme;
  borderWidth: BorderWidth;
  buttonSize: ButtonSize;
  headerSize: HeaderSize;
  logoSize: LogoSize;
}

export const CodeTheme: CodeTheme = {
  colors: {
    pink: Colors.PINK,
    paleBlue: Colors.PALE_BLUE,
    white: Colors.WHITE,
    offWhite: Colors.OFF_WHITE,
    lightGray: Colors.LIGHT_GRAY,
    lightGray1: Colors.LIGHT_GRAY_1,
    offBlack: Colors.OFF_BLACK,
    black: Colors.BLACK,
    blue: Colors.BLUE,
    green: Colors.GREEN,
    yellow: Colors.YELLOW,
    orange: Colors.ORANGE,
    gray: Colors.GRAY,
    gold: Colors.GOLD,
    offBlue: Colors.OFF_BLUE,
    absoluteBlack: Colors.ABSOLUTE_BLACK,
  },
  transition: {
    primary: 'all 0.4s ease',
  },
  weights: {
    regular: Weights.REGULAR,
    bold: Weights.BOLD,
  },
  fontSize: {
    defaultSize: 18,

    s1: 12,
    s2: 14,
    s3: 16,
    s4: 18,

    m1: 22,
    m2: 30,
    m3: 40,
  },
  lineHeight: {
    s1: 14,
    s2: 16,
    s3: 19,

    m1: 21,
    m2: 26,
    m3: 32,
    m4: 35,

    l1: 47,
  },
  breakpoints: {
    xs: 360,
    sm: 568,
    md: 767,
    lg: 1023,
    xl: 1200,
    xxl: 1440,
    arrowVisibilityHeight: 950,
  },
  spacing: {
    s1: 20,

    m1: 30,
    m2: 35,
    m3: 40,

    l1: 50,
    l2: 60,

    xl1: 65,
    xl2: 110,

    xxl1: 160,
    xxl2: 206,
    xxl3: 245,
  },
  borderRadius: {
    xss: 6,
    xs: 10,
    sm: 15,
    md: 20,
    xxl: 100,
  },
  borderWidth: {
    sm: 2,
  },
  buttonSize: {
    h1: 40,
    w1: 125,
    hm1: 40,
    wm1: 136,

    h2: 50,
    w2: 200,
    hm2: 50,
    wm2: 220,

    h3: 50,
    w3: 260,
    hm3: 50,
    wm3: 220,
  },
  headerSize: {
    paddingPrimary: 34,
    paddingTopMobile: 24,
    paddingBottomMobile: 20,
    // 34 (padding-top) + 36 (button) + 2*2 (border) + 17 (padding-bottom)
    heightDesktop: 91,
    // 22 (padding-top) + 36 (logo) + 11 (padding-bottom)
    heightMobile: 80,
  },
  logoSize: {
    h: 36,
    w: 40,
  },
};

export const ThemeProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => <StyledThemeProvider theme={CodeTheme}>{children}</StyledThemeProvider>;
