import { Link } from 'gatsby';
import styled from 'styled-components';

interface IActive {
  active: boolean;
}

interface IScroll {
  scroll: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const MainHeaderContainer = styled.div`
  background: ${(props: IScroll) =>
    props.scroll
      ? 'linear-gradient(180deg, rgba(23, 26, 34, 0.66) 0%, rgba(23, 26, 34, 0.31) 51.54%, rgba(23, 26, 34, 0) 100%);'
      : ''};
  padding-top: ${(props: IScroll) => (props.scroll ? 0 : 25)}px;
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  transition: 0.2s linear;
  z-index: 15;
`;

export const MainHeaderBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  max-width: 1230px;
  width: 100%;
  z-index: 1;
  @media (max-width: 768px) {
    max-width: 100%;
    justify-content: space-between;
  }
  @media (max-width: 1230px) {
    margin: 0 2%;
  }
`;

export const LogoBox = styled(Link)`
  padding: 10px 0;
  z-index: 2;
  position: relative;
  @media (min-width: 768px) {
    padding: 0;
  }
`;

export const Logo = styled.img`
  width: 187px;
  height: 29px;
  @media (max-width: ${(props) => props.theme.breakpoints.xl}px) {
    width: 141px;
    height: 21px;
  }
`;

export const Arrow = styled.div`
  border: 2px solid;

  border-image-source: linear-gradient(
    90deg,
    rgba(13, 16, 24, 0) 0%,
    rgba(13, 16, 24, 0) 0%,
    #ffffff 100%
  );

  position: absolute;
  width: 390px;
`;

export const Navigation = styled.nav`
  display: flex;
  align-items: center;
`;

export const NavigationList = styled.ul`
  display: flex;
  margin-right: ${(props: IActive) => (!props.active ? 30 : 0)}px;
  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    display: ${(props: IActive) => (props.active ? 'flex' : 'none')};
    flex-direction: column;
    padding-top: ${(props: IActive) => (props.active ? 153 : 113)}px;
    top: 0;
    left: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: #171a22;
    z-index: 1;
    font-weight: 400;
    text-transform: uppercase;
    margin-left: auto;
    align-items: center;
    text-align: left;
  }

  & button {
    margin-top: 80px;
    width: 350px;
    height: 50px;
  }
`;

export const NavigationItem = styled.li`
  border-top: 2px solid;
  border-image-source: linear-gradient(
    90deg,
    rgba(13, 16, 24, 0) 0%,
    rgba(13, 16, 24, 0) 0%,
    #ffffff 100%
  );

  & > a {
    font-size: 15px;
    margin-left: 20px;
    transition: all 0.2s linear;
    @media (max-width: ${(props) => props.theme.breakpoints.xl}px) {
      font-size: 13px;
    }
  }

  &:hover {
    text-decoration-line: underline;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const NavigationLink = styled.span<{ scroll?: boolean }>`
  color: #fff;
  margin-left: 20px;
  font-size: 15px;
  transition: all 0.2s linear;
  @media (max-width: ${(props) => props.theme.breakpoints.xl}px) {
    font-size: 13px;
  }
`;

export const BurgerBox = styled.div`
  z-index: 2;
  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    display: flex;
    align-items: center;
    position: relative;
    width: 20px;
    height: 13px;
    cursor: pointer;
    margin-left: 20px;

    & span,
    &:before,
    &:after {
      position: absolute;
      background-color: ${(props) => props.theme.colors.white};
      height: 1px;
      width: 100%;
      transition: all 0.3s ease 0s;
    }

    &:before,
    &:after {
      content: '';
    }

    &:before {
      top: ${(props: IActive) => (props.active ? '' : 0)};
      transform: ${(props: IActive) => (props.active ? 'rotate(45deg)' : '')};
      background-color: ${(props) =>
        props.active ? props.theme.colors.pink : ''};
    }

    & span {
      transform: ${(props: IActive) => (props.active ? 'scale(0)' : '')};
      background-color: ${(props) =>
        props.active ? props.theme.colors.pink : ''};
    }

    &:after {
      bottom: ${(props: IActive) => (props.active ? '' : 0)};
      transform: ${(props: IActive) => (props.active ? 'rotate(-45deg)' : '')};
      background-color: ${(props) =>
        props.active ? props.theme.colors.pink : ''};
    }
    ${(props) => {
      if (props.active) {
        return 'right: 35px;';
      }
      return null;
    }};
  }
`;

export const BurgerBtn = styled.span``;

export const BurgerImageFirst = styled.img`
  z-index: 3;
  position: absolute;
  left: 0;
  top: 110px;
  opacity: 0.4;
  margin-left: 20px;
`;

export const BurgerImageSecond = styled.img`
  z-index: 3;
  position: absolute;
  left: 0;
  top: 448px;
  opacity: 0.4;
  margin-left: 20px;
`;

export const BurgerImageThird = styled.img`
  z-index: 3;
  position: absolute;
  left: 0;
  opacity: 0.4;
  margin-left: 20px;
  top: 630px;
`;

export const CommunityWrapper = styled.div`
  width: 100%;
  max-width: 380px;
`;
export const CommunityBoxes = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 15px;
`;
export const CommunityBox = styled.a`
  background: linear-gradient(#0a0c13, #0a0c13) padding-box,
    linear-gradient(270deg, #5766cc 0%, #9c43a8 100%);
  border-width: 2px;
  border-color: transparent;
  border-style: solid;
  width: 110px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border-radius: 5px;
`;

export const PeopleCount = styled.div``;

export const CommunityImage = styled.img`
  margin-left: 11.2px;
`;

export const CommunityTitle = styled.span``;

export const BurgerContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const BurgerMenu = styled.div`
  background: ${(props) => props.theme.colors.black};
  height: 100vh;
  width: 100%;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 22;
  padding-left: 20px;
  padding-top: 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
  & button {
    width: 140px;
    height: 50px;
  }
`;

export const BurgerMenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 390px;
  width: 100%;
`;
export const BurgerMenuLogo = styled.img``;
export const BurgerMenuImage = styled.img`
  margin: 40px 0;
  margin-left: 0px;
  opacity: 0.2;
`;
export const BurgerNavigationBox = styled.div`
  width: 100%;
  max-width: 390px;
`;
export const BurgerMenuNavigation = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 350px;
`;
export const BurgerMenuItem = styled.li`
  width: 100%;
  margin-top: 10px;

  & > a {
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    text-transform: uppercase;
  }

  &:first-child {
    margin: 0;
  }
`;
export const BurgerMenuLink = styled.span`
  font-size: 30px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: left;
  text-transform: uppercase;
`;

export const BurgerMenuNftBtnContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  max-width: 380px;
  & button {
    width: 350px;
  }
`;

export const ExternalLink = styled.a``;

export const WalletStatusWrapper = styled.div``;

export const DropdownWrapper = styled.div`
  margin-left: 10px;
  margin-right: 40px;
  position: relative;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  &:after {
    content: '';
    width: 2px;
    height: 10px;
    background: ${(props) => props.theme.colors.gray};
    position: absolute;
    right: -20px;
    top: 5px;
  }
`;

export const DropdownText = styled.span`
  font-family: Titillium Web;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: right;
`;

export const AbsoluteWalletContainer = styled.div`
  position: absolute;
  right: 0;
  top: 50px;
  width: 250px;
  display: flex;
  justify-content: flex-end;
`;
