import React, { FC, useEffect, useState } from 'react';
import {
  CardsBox,
  CartBox,
  CartContainer,
  CartContent,
  CartFooter,
  CartHeader,
  CartMobileLogo,
  CartTitle,
  CartTitleBoxMobile,
  CloseBtn,
  CloseBtnBox,
  FooterContainer,
  TotalCost,
  TotalCostBox,
  TotalCostText,
} from '@/components/Cart/Cart.styled';
import CartCard from '@/components/CartCard/CartCard';
import Logo from '@/images/newHeader/Logo.svg';
import Footer from '@/components/Footer';
import { useAppSelector } from '@/hooks/redux';
import { useIsMobile } from '@/hooks/useMobile';
import { Button, ButtonStyleTypes } from '../UI';

interface ICartProps {
  isOpened: boolean;
  setOpened: (opened: boolean) => void;
}

const Cart: FC<ICartProps> = ({ isOpened, setOpened }) => {
  const [totalCost, setTotalCost] = useState<number>(0);
  const [disabled, setDisabled] = useState(true);
  const handleClose = () => {
    setOpened(false);
  };
  const { productsInCart } = useAppSelector((state) => state.cartReducer);

  useEffect(() => {
    let total = 0;
    productsInCart.forEach((product) => {
      total += (product?.count || 1) * product?.variants?.[0]?.price || 1;
    });
    setTotalCost(total);
  }, [productsInCart]);

  useEffect(() => {
    localStorage.setItem('products', JSON.stringify({ productsInCart }));
  }, [productsInCart]);

  useEffect(() => {
    if (productsInCart && productsInCart.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [productsInCart]);

  const isMobile = useIsMobile();

  return (
    <CartBox onClick={handleClose} isOpened={isOpened}>
      <CartContainer onClick={(e) => e.stopPropagation()} isOpened={isOpened}>
        <CartContent>
          <CartHeader>
            {!isMobile && <CartTitle>YOUR CART</CartTitle>}
            {isMobile && <CartMobileLogo src={Logo} />}
            <CloseBtnBox onClick={handleClose}>
              <CloseBtn />
            </CloseBtnBox>
          </CartHeader>
          {isMobile && (
            <CartTitleBoxMobile>
              <CartTitle>YOUR CART</CartTitle>
            </CartTitleBoxMobile>
          )}
          <CardsBox>
            {productsInCart.map((product, idx) => (
              <CartCard
                key={idx}
                productId={product?.id}
                productVariants={product?.variants}
                count={product?.count}
                checkoutStyles={false}
                costPerUnit={product?.variants?.[0]?.price}
                name={product?.title}
                isVip={product?.title.indexOf('VIP') !== -1}
                showRemoveButton
              />
            ))}
          </CardsBox>
        </CartContent>
        <FooterContainer>
          <CartFooter>
            <TotalCostBox>
              <TotalCostText>Subtotal:</TotalCostText>
              <TotalCost>${totalCost.toFixed(2)}</TotalCost>
            </TotalCostBox>
            <Button
              styleType={ButtonStyleTypes.DEFAULT}
              width="285px"
              link="/checkout"
              onClick={handleClose}
              disabled={disabled}
            >
              Proceed to checkout
            </Button>
          </CartFooter>
        </FooterContainer>
        {isMobile && <Footer />}
      </CartContainer>
    </CartBox>
  );
};

export default Cart;
