import styled from 'styled-components';

const Wrapper = styled.div`
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: -2;
`;

export default Wrapper;
