import { createGlobalStyle } from 'styled-components';

import './fonts.css';

import { CodeTheme } from './theme';

export const GlobalStyle = createGlobalStyle`

    body {
        font-family: 'Titillium Web', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
        color: ${CodeTheme.colors.white};
        &.lock {
            overflow: hidden;
        }
      background: ${CodeTheme.colors.black};
      & .ytp-chrome-top {
        display: none;
      }
    }
*{padding:0;margin:0;border:0;}
*,*:before,*:after{-moz-box-sizing:border-box;-webkit-box-sizing:border-box;box-sizing:border-box;}
:focus,:active{outline:none;}
a:focus,a:active{outline:none;}
nav,footer,header,aside{display:block;}
html,body{width:100%;font-size:100%;line-height:1;font-size:18px;-ms-text-size-adjust:100%;-moz-text-size-adjust:100%;-webkit-text-size-adjust:100%;}
input,button,textarea,a,a:visited,h1,h2,h3,h4,h5,h6{font-family:inherit;font-size:inherit;font-weight:inherit;line-height:inherit;letter-spacing:inherit;color:inherit;}
input::-ms-clear{display:none;}
button{cursor:pointer;}
button::-moz-focus-inner{padding:0;border:0;}
a,a:visited{text-decoration:none;cursor:pointer;}
a:hover{text-decoration:none;}
ul li{list-style:none;}
img{vertical-align:top;}
    .ytp-chrome-top {
      display: none;
    }
`;
