import { accountNftSlice } from '@/Store/Reducers/accountNftsReducer';
import { accountTokensSlice } from '@/Store/Reducers/accountTokensReducer';
import { AppDispatch } from '@/Store';
import { fetchAccountNfts, fetchAccountTokens } from '@/utils/api';

export const fetchAccountActionNfts =
  (walletAddress: string, perPage: number, currentPage: number) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(accountNftSlice.actions.accountFetching());
      const products = await fetchAccountNfts(
        walletAddress,
        perPage,
        currentPage,
      );

      dispatch(accountNftSlice.actions.accountFetchingSuccess(products));
    } catch (e) {
      dispatch(accountNftSlice.actions.accountFetchingError(e.toString()));
    }
  };

export const fetchAccountActionTokens =
  (walletAddress: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(accountTokensSlice.actions.accountFetching());
      const products = await fetchAccountTokens(walletAddress);

      dispatch(accountTokensSlice.actions.accountFetchingSuccess(products));
    } catch (e) {
      dispatch(accountTokensSlice.actions.accountFetchingError(e.toString()));
    }
  };

export const setWalletConnectedAction =
  (status: boolean) => async (dispatch: AppDispatch) => {
    dispatch(accountNftSlice.actions.setIsWalletConnected(status));
  };

export const setAccountActionNftsCurrentPage =
  (value: number) => async (dispatch: AppDispatch) => {
    dispatch(accountNftSlice.actions.setCurrentNftsPage(value));
  };
