import { IProduct } from '@/types/products';
import { cartSlice } from '@/Store/Reducers/cartReducer';
import { AppDispatch } from '@/Store';

export const addToCart =
  (product: IProduct) => async (dispatch: AppDispatch) => {
    dispatch(cartSlice.actions.addProductToCart(product));
  };

export const removeFromCart = (id: number) => async (dispatch: AppDispatch) => {
  dispatch(cartSlice.actions.removeFromCart({ id }));
};

export const toggleCart =
  (isOpen: boolean) => async (dispatch: AppDispatch) => {
    dispatch(cartSlice.actions.toggleCart({ isOpen }));
  };

export const setCount =
  (idx: number, value: number) => async (dispatch: AppDispatch) => {
    dispatch(cartSlice.actions.setCount({ idx, value }));
  };
