import React, { useState, useEffect, FC } from 'react';
import {
  Copyright,
  FooterBottom,
  FooterContainer,
  FooterContentFirst,
  FooterContentSecond,
  FooterContentSecondBottom,
  FooterInput,
  InputBox,
  Link,
  LinkGroup,
  LinkGroups,
  LinkGroupTitle,
  LinkList,
  LinkListItem,
  Logo,
  PrivacyBlock,
  PrivacyLink,
  SocialBlock,
  SocialBlockImage,
  SocialLink,
  InputStatus,
  ControllsContainer,
  FooterContent,
  FooterDisclaimerBox,
  FooterDisclaimerTitle,
  FooterDisclaimer,
} from '@/components/Footer/Footer.styled';
import logo from '@/images/newHeader/Logo.svg';
import Discord from '@/images/newFooter/Discord.svg';
import Twitter from '@/images/newFooter/Twitter.svg';
import Telegram from '@/images/newFooter/Telegram.svg';
import Facebook from '@/images/newFooter/Facebook.svg';
import Instagram from '@/images/newFooter/Instagram.svg';
import Line from '@/images/newFooter/Line.svg';
import Snapchat from '@/images/newFooter/Snapchat.svg';
import Medium from '@/images/newFooter/Medium.svg';
import { SocialLinks, ExternalLinks } from '@/utils/constants';
import { Button, ButtonStyleTypes, BorderType } from '@/components/UI/Button';
import { subscribe } from '@/utils/api';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import Container from '@/components/Container';

interface IFooterProps {
  isNftsPage?: boolean;
}

const Footer: FC<IFooterProps> = ({ isNftsPage }) => {
  const [isSubmitPressed, setIsSubmitPressed] = useState(false);
  const [emailState, setEmailState] = useState({
    value: '',
    errorMessage: null,
    successMessage: '',
  });

  useEffect(() => {
    const lastAtPos = emailState.value.lastIndexOf('@');
    const lastDotPos = emailState.value.lastIndexOf('.');

    if (
      !(
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        emailState.value.indexOf('@@') === -1 &&
        lastDotPos > 2 &&
        emailState.value.length - lastDotPos > 2
      )
    ) {
      setEmailState({ ...emailState, errorMessage: 'Email is not valid' });
    } else {
      setEmailState({ ...emailState, errorMessage: '' });
    }
  }, [emailState.value]);

  const onSubmit = () => {
    setIsSubmitPressed(true);

    if (emailState.errorMessage === '') {
      subscribe(emailState.value).then(() => {
        setEmailState({
          ...emailState,
          successMessage: 'Successfully subscribed',
        });
      });
    }
  };

  return (
    <FooterContainer isNftsPage={isNftsPage}>
      <Container>
        <FooterContent>
          <FooterContentFirst>
            <Logo src={logo} />
            <InputBox>
              <ControllsContainer>
                <FooterInput
                  type="email"
                  placeholder="Email"
                  width="241px"
                  onChange={(e) =>
                    setEmailState({ ...emailState, value: e.target.value })
                  }
                />
                <Button
                  height="50px"
                  width="108px"
                  styleType={ButtonStyleTypes.DEFAULT}
                  onClick={onSubmit}
                  borderType={BorderType.NO_BORDER_RADIUS}
                >
                  Submit
                </Button>
              </ControllsContainer>
              {isSubmitPressed && (
                <InputStatus>
                  {emailState.successMessage || emailState.errorMessage}
                </InputStatus>
              )}
            </InputBox>

            <FooterDisclaimerBox>
              <FooterDisclaimerTitle>Disclaimer</FooterDisclaimerTitle>
              <FooterDisclaimer>
                Nothing in this website constitutes financial advice, and it is
                always recommended to consult a qualified financial advisor
                before participating in any token or NFT purchases.
              </FooterDisclaimer>
            </FooterDisclaimerBox>
            <FooterBottom>
              <Copyright>© SKY/BREAKERS. All rights reserved.</Copyright>
            </FooterBottom>
          </FooterContentFirst>
          <FooterContentSecond>
            <LinkGroups>
              <LinkGroup>
                <LinkGroupTitle>Crypto</LinkGroupTitle>
                <LinkList>
                  <LinkListItem>
                    <Link href={ExternalLinks.WHITEPAPER} target="_blank">
                      Litepaper
                    </Link>
                  </LinkListItem>{' '}
                  <LinkListItem>
                    <AnchorLink stripHash to="/tokenomics">
                      Tokenomics
                    </AnchorLink>
                  </LinkListItem>{' '}
                </LinkList>
              </LinkGroup>{' '}
              <LinkGroup>
                <LinkGroupTitle>GamePlay</LinkGroupTitle>
                <LinkList>
                  <LinkListItem>
                    <Link
                      href="https://skybreakers-io.gitbook.io/skybreakers.io/gameplay-mechanics"
                      target="_blank"
                    >
                      Gameplay Mechanics
                    </Link>
                  </LinkListItem>{' '}
                  <LinkListItem>
                    <Link
                      href="https://skybreakers-io.gitbook.io/skybreakers.io/helicopter-upgrades"
                      target="_blank"
                    >
                      Helicopter Upgrades
                    </Link>
                  </LinkListItem>{' '}
                  <LinkListItem>
                    <Link
                      href="https://skybreakers-io.gitbook.io/skybreakers.io/maps-and-modes"
                      target="_blank"
                    >
                      Maps And Modes
                    </Link>
                  </LinkListItem>{' '}
                </LinkList>
              </LinkGroup>{' '}
              <LinkGroup>
                <LinkGroupTitle>Helipedia</LinkGroupTitle>
                <LinkList>
                  <LinkListItem>
                    <Link
                      href="https://skybreakers-io.gitbook.io/skybreakers.io/types-of-helicopters"
                      target="_blank"
                    >
                      Reconnaissance helicopter
                    </Link>
                  </LinkListItem>{' '}
                  <LinkListItem>
                    <Link
                      href="https://skybreakers-io.gitbook.io/skybreakers.io/types-of-helicopters"
                      target="_blank"
                    >
                      Assault helicopter
                    </Link>
                  </LinkListItem>{' '}
                  <LinkListItem>
                    <Link
                      href="https://skybreakers-io.gitbook.io/skybreakers.io/types-of-helicopters"
                      target="_blank"
                    >
                      Fire support helicopter
                    </Link>
                  </LinkListItem>{' '}
                </LinkList>
              </LinkGroup>
            </LinkGroups>
            <FooterContentSecondBottom>
              <PrivacyBlock>
                <PrivacyLink
                  href={ExternalLinks.PRIVACY_POLICY}
                  target="_blank"
                >
                  Privacy Policy
                </PrivacyLink>
                <PrivacyLink
                  href={ExternalLinks.TERMS_OF_CONDITIONS}
                  target="_blank"
                >
                  Terms of Service
                </PrivacyLink>
              </PrivacyBlock>
              <SocialBlock>
                <SocialLink target="_blank" href={SocialLinks.DISCORD}>
                  <SocialBlockImage src={Discord} />
                </SocialLink>
                <SocialLink target="_blank" href={SocialLinks.TELEGRAM}>
                  <SocialBlockImage src={Telegram} />
                </SocialLink>
                <SocialLink target="_blank" href={SocialLinks.TWITTER}>
                  <SocialBlockImage src={Twitter} />
                </SocialLink>
                <SocialLink target="_blank" href={SocialLinks.FACEBOOK}>
                  <SocialBlockImage src={Facebook} />
                </SocialLink>
                <SocialLink target="_blank" href={SocialLinks.INSTAGRAM}>
                  <SocialBlockImage src={Instagram} />
                </SocialLink>
                <SocialLink target="_blank" href={SocialLinks.SNAPCHAT}>
                  <SocialBlockImage src={Snapchat} />
                </SocialLink>
                <SocialLink target="_blank" href={SocialLinks.MEDIUM}>
                  <SocialBlockImage src={Medium} />
                </SocialLink>
                <SocialLink target="_blank" href={SocialLinks.LINE}>
                  <SocialBlockImage src={Line} />
                </SocialLink>
              </SocialBlock>
            </FooterContentSecondBottom>
          </FooterContentSecond>
        </FooterContent>
      </Container>
    </FooterContainer>
  );
};
export default Footer;
