import styled from 'styled-components';

export const Arrow = styled.div<{ isRotated: boolean }>`
  width: 8px;
  height: 8px;
  border-top: 1px solid ${(props) => props.theme.colors.pink};
  border-right: 1px solid ${(props) => props.theme.colors.pink};

  ${(props) => {
    if (props.isRotated) {
      return `
        transform: rotate(315deg);
        margin-top: 5px;
      `;
    }
    return `
      transform: rotate(135deg);
    `;
  }}
`;

export const MoreDropdownContainer = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
  font-size: 15px;
  line-height: 20px;
  margin: 0 20px;
`;

export const StatusBadge = styled.span`
  display: flex;
  align-items: center;
  padding: 0 5px;
  color: ${(props) => props.theme.colors.pink};

  & > ${Arrow} {
    margin-left: 5px;
    margin-bottom: 4px;
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 25px;
  z-index: 10;
  width: 180px;
  height: 100px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  border-radius: 20px;
  border: 1px solid ${(props) => props.theme.colors.pink};

  & > span {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    &:first-child {
      margin-bottom: 10px;
    }

    &:last-child {
      margin-top: 10px;
    }
  }
`;
