import styled from 'styled-components';

export const FooterContainer = styled.div<{ isNftsPage: boolean }>`
  height: 400px;
  background-color: #0a0c13;
  padding-left: 105px;
  padding-top: 70px;
  padding-bottom: 30px;
  display: flex;
  z-index: 2;
  ${(props) => {
    if (props.isNftsPage) {
      return 'margin-top: 123px;';
    }
    return null;
  }};
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 0;
    position: relative;
    height: 480px;
    ${(props) => {
      if (props.isNftsPage) {
        return 'margin-top: 66px;';
      }
      return null;
    }};
  }
`;

export const FooterContent = styled.div`
  width: 100%;
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;
    padding-left: 20px;
  }
`;

export const FooterContentFirst = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 50%;
  @media (max-width: 768px) {
    justify-content: flex-start;
    width: 100%;
    margin-top: 40px;
  }
`;
export const Logo = styled.img`
  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;
export const FooterBottom = styled.div``;
export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`;
export const FooterDisclaimerBox = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 312px;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`;
export const FooterDisclaimerTitle = styled.span`
  font-size: 15px;
`;
export const FooterDisclaimer = styled.span`
  margin-top: 10px;
  color: ${(props) => props.theme.colors.gray};
  font-size: 15px;
`;
export const FooterInput = styled.input`
  border-radius: 5px 0px 0px 5px;
  max-width: 277px;
  height: 50px;
  background: #798094;
  padding-left: 20px;
  padding-top: 17px;
  padding-bottom: 15px;
  font-size: 15px;
  color: black;
  cursor: default;

  @media (max-width: 768px) {
    max-width: 240px;
  }

  @media (max-width: 500px) {
    max-width: unset;
    width: 100%;
  }
`;
export const SubmitBtn = styled.button`
  border-radius: 0px 5px 5px 0px;
  font-size: 15px;
  height: 50px;
  background: ${(props) => props.theme.colors.pink};
  width: 113px;
`;
export const Copyright = styled.span`
  @media (max-width: 768px) {
    position: absolute;
    bottom: 45px;
    font-size: 15px;
  }
  @media (max-width: 400px) {
    bottom: 25px;
  }
`;
export const FooterContentSecond = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 768px) {
    width: 90%;
  }
]`;
export const LinkGroups = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 500px) {
  }
`;
export const LinkGroup = styled.div`
  margin-right: 10%;
  max-height: 165px;
  @media (max-width: 768px) {
    &:first-child {
      margin-right: 25%;
    }
    &:last-child {
      display: none;
    }
  }
`;
export const LinkGroupTitle = styled.span`
  font-size: calc(12px + 5 * (100vw - 420px) / 880);
  color: #486dd3;
  @media (max-width: 768px) {
    font-size: 15px;
  }
  @media (min-width: 1230px) {
    font-size: 20px;
  }
`;
export const LinkList = styled.ul`
  list-style: none;
  margin-top: 10px;
`;
export const LinkListItem = styled.li`
  margin-top: 10px;
  &:hover {
    text-decoration-line: underline;
  }
`;

export const Link = styled.a`
  font-size: calc(12px + 5 * (100vw - 420px) / 880);
  @media (max-width: 768px) {
    font-size: 15px;
  }
  @media (min-width: 1230px) {
    font-size: 20px;
  }
`;

export const FooterContentSecondBottom = styled.div`
  display: flex;
  margin-top: 120px;
`;
export const PrivacyBlock = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`;

export const PrivacyLink = styled.a`
  margin-right: 15px;
  font-size: 15px;
  @media (max-width: 768px) {
    margin-right: 13px;
    font-size: 13px;
  }
  &:hover {
    text-decoration-line: underline;
  }
`;
export const SocialBlock = styled.div`
  padding-right: 106px;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const SocialLink = styled.a`
  cursor: pointer;
  margin-right: 16px;
  &:last-child {
    margin-right: 0;
  }
`;

export const SocialBlockImage = styled.img`
  &:hover {
    filter: brightness(1.5);
  }
`;

export const InputStatus = styled.span`
  font-size: 12px;
  margin-top: 10px;
`;

export const ControllsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media (max-width: 768px) {
    & input {
      max-width: 241px;
      width: 100% !important;
    }
    & button {
      width: 108px !important;
    }
    padding-right: 10px;
  }
`;
