import styled from 'styled-components';
import CardImage from '@/images/Cart/CardImage.png';
import PlusBtnImage from '@/images/nftsIntro/Add.svg';
import HideBtnImage from '@/images/nftsIntro/Hide.svg';

export const CartBox = styled.div<{ isOpened: boolean }>`
  position: fixed;
  z-index: 40;
  width: 100%;
  height: ${(props) => (props.isOpened ? '100%' : 'auto')};
`;

export const CartContainer = styled.div<{ isOpened: boolean }>`
  width: 705px;
  height: 100%;
  background: ${(props) => props.theme.colors.black};
  right: 0;
  top: 0;
  z-index: 40;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: ${(props) =>
    props.isOpened ? 'translateX(0)' : 'translateX(100%)'};
  position: fixed;
  transition: all ease 0.5s;
  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
`;

export const CartMobileLogo = styled.img``;

export const CartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    max-width: 390px;
    width: 100%;
    margin-bottom: 67px;
  }
`;

export const CartTitleBoxMobile = styled.div`
  max-width: 345px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

export const CartTitle = styled.h2`
  font-size: 50px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: 0px;
  text-align: left;
  @media (max-width: 767px) {
    max-width: 390px;
  }
`;

export const CloseBtnBox = styled.div`
  position: relative;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 4px;
`;
export const CloseBtn = styled.span`
  &:after {
    content: '';
    width: 20px;
    height: 1px;
    position: absolute;
    background: ${(props) => props.theme.colors.pink};
    transform: rotate(-45deg);
    @media (max-width: 767px) {
      background: ${(props) => props.theme.colors.white};
    }
  }
  &:before {
    content: '';
    width: 20px;
    height: 1px;
    position: absolute;
    background: ${(props) => props.theme.colors.pink};
    transform: rotate(45deg);
    @media (max-width: 767px) {
      background: ${(props) => props.theme.colors.white};
    }
  }
`;

export const CartContent = styled.div`
  padding: 90px 55px 0px 75px;
  display: flex;
  flex-direction: column;
  max-height: 69vh;
  @media (max-width: 767px) {
    padding-top: 52px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0;
    align-items: center;
  }
`;

export const CardsBox = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 390px;
    width: 100%;
  }
`;

export const Card = styled.div`
  display: flex;
  background: linear-gradient(#0a0c13, #0a0c13) padding-box,
    linear-gradient(180deg, #486dd3 0%, #0d1018 100%) border-box;
  width: 525px;
  border: 1px solid transparent;
  height: 145px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 15px 20px 15px 15px;
  margin-top: 15px;
  &:first-child {
    margin-top: 0;
  }
`;
export const CardContent = styled.div`
  width: 100%;
  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
  }
`;
export const ImageBox = styled.div`
  background: url(${CardImage});
  height: 115px;
  width: 174px;
  border-radius: 5px;
  margin-right: 15px;
`;
export const CardHeader = styled.div`
  font-size: 30px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 5px;
`;
export const CostPerUnit = styled.span`
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: ${(props) => props.theme.colors.blue};
`;
export const UnitText = styled.span`
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
`;
export const BtnBox = styled.div`
  display: flex;
  align-items: center;
`;
export const PlusBtn = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid ${(props) => props.theme.colors.pink};
  border-radius: 3px;
  background: url(${PlusBtnImage}) no-repeat center;
  margin-left: 10px;
  cursor: pointer;
`;
export const Count = styled.span``;
export const HideBtn = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid ${(props) => props.theme.colors.pink};
  border-radius: 3px;
  background: url(${HideBtnImage}) no-repeat center;
  margin-right: 10px;
  cursor: pointer;
`;
export const CostBox = styled.div`
  display: flex;
  align-items: flex-end;
`;
export const Cost = styled.div`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: right;
`;
export const Currency = styled.span`
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: right;
  margin-left: 11px;
`;

export const FooterContainer = styled.div`
  margin-top: 15px;
  border-top: 1px solid #0a0c13;
  @media (max-width: 767px) {
    margin: 23px 0 100px 0;
  }
`;

export const CartFooter = styled.div`
  padding: 37px 55px 61px 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 767px) {
    flex-direction: column;
    padding: 0 21px;
  }
`;

export const TotalCostBox = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0 32px 0;
    max-width: 390px;
  }
`;
export const TotalCostText = styled.span`
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
`;
export const TotalCost = styled.h3`
  margin-top: 6px;
  font-size: 30px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: left;
`;
export const CardBtn = styled.button`
  width: 285px;
  height: 50px;
  border-radius: 5px;
  background: ${(props) => props.theme.colors.pink};
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  @media (max-width: 767px) {
    margin-top: 46px;
  }
`;
