import Collection1 from '@/images/Collections/DesertNFT.png';
import CollectionSmall1 from '@/images/Cart/DesertNFT.png';
import Collection2 from '@/images/Collections/CityNFT.png';
import CollectionSmall2 from '@/images/Cart/CityNFT.png';
import Collection3 from '@/images/Collections/JungleNFT.png';
import CollectionSmall3 from '@/images/Cart/JungleNFT.png';
import CollectionToken from '@/images/Collections/Coin.png';
import CollectionSmalltoken from '@/images/Cart/Coin.png';
import CollectionDef from '@/images/Collections/Collection1.png';
import CollectionSmallDef from '@/images/Cart/CardImage.png';

export function getImageByProduct(product, size = '350_250') {
  if (product?.title.indexOf('Battle') !== -1)
    return size === '127_115' ? CollectionSmall1 : Collection1;
  if (product?.title.indexOf('VIP') !== -1)
    return size === '127_115' ? CollectionSmall2 : Collection2;
  if (product?.title.indexOf('Mystery') !== -1)
    return size === '127_115' ? CollectionSmall3 : Collection3;
  if (product?.title.indexOf('Token') !== -1)
    return size === '127_115' ? CollectionSmalltoken : CollectionToken;

  return size === '127_115' ? CollectionSmallDef : CollectionDef;
}
