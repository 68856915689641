/* eslint-disable */

import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3Modal from 'web3modal';

const env =
  process.env.NODE_ENV && process.env.GATSBY_ISPROD === 'TRUE'
    ? process.env.NODE_ENV
    : 'development';

const optionsProviders = {
  production: {
    rpc: {
      56: 'https://bsc-dataseed.binance.org/',
    },
    network: 'binance',
  },
  development: {
    rpc: {
      97: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
    },
    network: 'binance-testnet',
  },
};

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: optionsProviders[env],
  },
};

let web3Modal;
if (typeof window !== 'undefined') {
  web3Modal = new Web3Modal({
    // network: 'mainet', // optional
    cacheProvider: true,
    providerOptions, // required
  });
}

const exchange = {
  usdc: '0x64544969ed7ebf5f083679233325356ebe738930',
  usdcProd: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
  usdt: '0x5af505c9Ec63e5d5FB954bd7daF616c5dd1AE855',
  usdtProd: '0x55d398326f99059ff775485246999027b3197955',
  abi: [
    'function name() view returns (string)',
    'function symbol() view returns (string)',
    'function balanceOf(address) view returns (uint)',
    'function transfer(address to, uint amount)',
    'event Transfer(address indexed from, address indexed to, uint amount)',
  ],
};

const networks = {
  bscTest: {
    chainId: `0x${Number(97).toString(16)}`,
    chainName: 'Binance Chain Testnet',
    nativeCurrency: {
      name: 'Binance Chain Testnet',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
    blockExplorerUrls: ['https://testnet.bscscan.com'],
  },
  bsc: {
    chainId: `0x${Number(56).toString(16)}`,
    chainName: 'Binance Smart Chain Mainnet',
    nativeCurrency: {
      name: 'Binance Chain Native Token',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: [
      'https://bsc-dataseed1.binance.org',
      'https://bsc-dataseed2.binance.org',
      'https://bsc-dataseed3.binance.org',
      'https://bsc-dataseed4.binance.org',
      'https://bsc-dataseed1.defibit.io',
      'https://bsc-dataseed2.defibit.io',
      'https://bsc-dataseed3.defibit.io',
      'https://bsc-dataseed4.defibit.io',
      'https://bsc-dataseed1.ninicoin.io',
      'https://bsc-dataseed2.ninicoin.io',
      'https://bsc-dataseed3.ninicoin.io',
      'https://bsc-dataseed4.ninicoin.io',
      'wss://bsc-ws-node.nariox.org',
    ],
    blockExplorerUrls: ['https://bscscan.com'],
  },
};

const handleErrorText = (txt1, txt2) => {
  if (!txt1 && !txt2) return 'Something went wrong';
  if (
    txt1?.includes('gas required exceeds allowance') ||
    txt1?.includes('transfer amount exceeds balance') ||
    txt1?.includes('insufficient funds') ||
    txt1?.includes('insufficient ballance') ||
    txt1?.includes('gas required exceeds allowance') ||
    txt1?.includes('cannot estimate gas') ||
    txt2?.includes('insufficient funds') ||
    txt2?.includes('insufficient ballance') ||
    txt2?.includes('cannot estimate gas')
  ) {
    return 'Unfortunately, you don’t seem to have enough in your wallet to complete this transaction. Please double check your balance and try again.';
  }
  return txt1 || txt2;
};

function ellipseAddress(address = '', width = 5) {
  if (!address) {
    return '';
  }
  return `${address.slice(0, width)}...${address.slice(-width)}`;
}

const updateGA = (res, order, isCheckout) => {
  // @ts-ignore
  window?.dataLayer?.push({ ecommerce: null });
  if (!isCheckout) {
    // @ts-ignore
    return window?.dataLayer?.push({
      event: 'purchase',
      ecommerce: {
        purchase: {
          actionField: {
            id: res.hash,
            affiliation: 'SkyBreakers',
            revenue: order.sum,
          },
          products: [
            {
              name: 'NFT token',
              id: 'NFT token',
              price: order.price,
              quantity: order.qty,
            },
          ],
        },
      },
    });
  }
  const products = order.cart.map((item) => ({
    name: item.title,
    id: item.product_id,
    price: item.unit_price,
    quantity: item.quantity,
  }));
  // @ts-ignore
  dataLayer.push({
    event: 'purchase',
    ecommerce: {
      purchase: {
        actionField: {
          id: res.hash,
          affiliation: 'SkyBreakers',
          revenue: order.sum,
        },
        products,
      },
    },
  });
};

export {
  optionsProviders,
  exchange,
  networks,
  ellipseAddress,
  web3Modal,
  handleErrorText,
  updateGA,
};
