import React, { FC, useEffect, useState } from 'react';
import {
  BurgerBox,
  BurgerBtn,
  BurgerContainer,
  BurgerImageFirst,
  BurgerImageSecond,
  BurgerImageThird,
  BurgerMenu,
  BurgerMenuHeader,
  BurgerMenuImage,
  BurgerMenuItem,
  BurgerMenuLogo,
  BurgerMenuNavigation,
  BurgerNavigationBox,
  CommunityBox,
  CommunityBoxes,
  CommunityImage,
  CommunityTitle,
  CommunityWrapper,
  Container,
  Logo,
  LogoBox,
  MainHeaderBlock,
  MainHeaderContainer,
  Navigation,
  NavigationItem,
  NavigationList,
  NavigationLink,
  ExternalLink,
  PeopleCount,
} from '@/components/Header/Header.styled';
import LogoImage from '@/images/newHeader/Logo.svg';
import RightArrow from '@/images/Vector-right.svg';
import BurgerLogo from '@/images/newHeader/BurgerLogo.svg';
import Telegram from '@/images/Community/Telegram.svg';
import Discord from '@/images/Community/Discord.svg';
import Twitter from '@/images/Community/Twitter.svg';
import Facebook from '@/images/Community/Facebook.svg';
import Instagram from '@/images/Community/Instagram.svg';
import Line from '@/images/Community/Line.svg';
import Snapchat from '@/images/Community/Snapchat.svg';
import Medium from '@/images/Community/Medium.svg';
import { formatSocialUsersCount } from '@/utils/numbers';
import Cart from '@/components/Cart/Cart';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { toggleCart } from '@/Store/actions/cart';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { Link } from 'gatsby';
import { ExternalLinks, SocialLinks } from '@/utils/constants';
import {
  fetchAccountActionNfts,
  fetchAccountActionTokens,
} from '@/Store/actions/account';

interface IHeaderProps {
  isAccountPage?: boolean;
}

const Header: FC<IHeaderProps> = ({ isAccountPage }) => {
  const [isBurgerOpen, setBurgerOpen] = useState<boolean>(false);
  const [scroll, setScroll] = useState<boolean>(false);
  const { socialInfo } = useAppSelector((state) => state.socialReducer);
  const { isCartOpen } = useAppSelector((state) => state.cartReducer);
  const { address } = useAppSelector((state) => state.cryptoReducer);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isBurgerOpen) {
      document.body.style.overflow = 'auto';
    } else {
      document.body.style.overflow = 'hidden';
    }
  }, [isBurgerOpen]);

  const handleBurgerOpen = () => {
    setBurgerOpen(!isBurgerOpen);
  };

  const handleScroll = () => {
    if (window.scrollY >= 52) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  const handleCartOpen = () => {
    dispatch(toggleCart(!isCartOpen));
  };

  useEffect(() => {
    if (isCartOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [isCartOpen]);

  useEffect(() => {
    if (typeof window !== undefined) {
      window.addEventListener('scroll', handleScroll);
    }
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const accountActionNftsCurrentPage = useAppSelector(
    (state) => state.accountNftsReducer.currentPage,
  );
  const accountActionNftsperPage = useAppSelector(
    (state) => state.accountNftsReducer.perPage,
  );

  useEffect(() => {
    if (address && isAccountPage) {
      dispatch(
        fetchAccountActionNfts(
          address,
          accountActionNftsperPage,
          accountActionNftsCurrentPage,
        ),
      );
      dispatch(fetchAccountActionTokens(address));
    }
  }, [address, accountActionNftsperPage, accountActionNftsCurrentPage]);

  return (
    <Container>
      <Cart isOpened={isCartOpen} setOpened={handleCartOpen} />
      <MainHeaderContainer scroll={scroll}>
        <MainHeaderBlock>
          <LogoBox to="/">
            <Logo src={LogoImage} />
          </LogoBox>
          <BurgerContainer>
            {!isBurgerOpen && (
              <Navigation>
                <NavigationList active={isBurgerOpen}>
                  <NavigationItem>
                    <AnchorLink stripHash to="/#gameplay">
                      Game
                    </AnchorLink>
                  </NavigationItem>
                  <NavigationItem>
                    <AnchorLink stripHash to="/#team">
                      Team
                    </AnchorLink>
                  </NavigationItem>
                  <NavigationItem>
                    <AnchorLink stripHash to="/#roadmap">
                      Roadmap
                    </AnchorLink>
                  </NavigationItem>
                  <NavigationItem>
                    <AnchorLink stripHash to="/tokenomics">
                      Tokenomics
                    </AnchorLink>
                  </NavigationItem>
                  <NavigationItem>
                    <NavigationLink scroll={scroll}>
                      <ExternalLink
                        href={ExternalLinks.WHITEPAPER}
                        target="_blank"
                      >
                        Litepaper➚
                      </ExternalLink>
                    </NavigationLink>
                  </NavigationItem>
                </NavigationList>
                {isBurgerOpen && <BurgerImageFirst src={RightArrow} />}
                {isBurgerOpen && <BurgerImageSecond src={RightArrow} />}
                {isBurgerOpen && <BurgerImageThird src={RightArrow} />}
              </Navigation>
            )}
            <BurgerBox onClick={handleBurgerOpen} active={isBurgerOpen}>
              <BurgerBtn />
            </BurgerBox>
          </BurgerContainer>
        </MainHeaderBlock>
      </MainHeaderContainer>
      {isBurgerOpen && (
        <BurgerMenu>
          <BurgerMenuHeader>
            <Link to="/">
              <BurgerMenuLogo src={BurgerLogo} />
            </Link>
            <BurgerBox onClick={handleBurgerOpen} active={isBurgerOpen}>
              <BurgerBtn />
            </BurgerBox>
          </BurgerMenuHeader>
          <BurgerMenuImage src={RightArrow} />
          <BurgerNavigationBox>
            <BurgerMenuNavigation>
              <BurgerMenuItem>
                <AnchorLink
                  onAnchorLinkClick={() => setBurgerOpen(false)}
                  stripHash
                  to="/#gameplay"
                >
                  Game
                </AnchorLink>
              </BurgerMenuItem>{' '}
              <BurgerMenuItem>
                <AnchorLink
                  onAnchorLinkClick={() => setBurgerOpen(false)}
                  stripHash
                  to="/#team"
                >
                  Team
                </AnchorLink>
              </BurgerMenuItem>{' '}
              <BurgerMenuItem>
                <AnchorLink
                  onAnchorLinkClick={() => setBurgerOpen(false)}
                  stripHash
                  to="/#roadmap"
                >
                  Roadmap
                </AnchorLink>
              </BurgerMenuItem>
              <BurgerMenuItem>
                <AnchorLink
                  onAnchorLinkClick={() => setBurgerOpen(false)}
                  stripHash
                  to="/tokenomics"
                >
                  Tokenomics
                </AnchorLink>
              </BurgerMenuItem>{' '}
              <BurgerMenuItem>
                <ExternalLink href={ExternalLinks.WHITEPAPER} target="_blank">
                  Litepaper➚
                </ExternalLink>
              </BurgerMenuItem>{' '}
            </BurgerMenuNavigation>
          </BurgerNavigationBox>
          <BurgerMenuImage src={RightArrow} />
          {isBurgerOpen && (
            <CommunityWrapper>
              <CommunityTitle>Join the community:</CommunityTitle>
              <CommunityBoxes>
                <CommunityBox target="_blank" href={SocialLinks.DISCORD}>
                  <PeopleCount>
                    {' '}
                    {formatSocialUsersCount(socialInfo.discord)}
                  </PeopleCount>
                  <CommunityImage src={Discord} />
                </CommunityBox>
                <CommunityBox target="_blank" href={SocialLinks.TELEGRAM}>
                  <PeopleCount>
                    {' '}
                    {formatSocialUsersCount(socialInfo.telegram)}
                  </PeopleCount>
                  <CommunityImage src={Telegram} />
                </CommunityBox>
                <CommunityBox target="_blank" href={SocialLinks.TWITTER}>
                  <PeopleCount>
                    {' '}
                    {formatSocialUsersCount(socialInfo.twitter)}
                  </PeopleCount>
                  <CommunityImage src={Twitter} />
                </CommunityBox>
              </CommunityBoxes>
              <CommunityBoxes>
                <CommunityBox target="_blank" href={SocialLinks.FACEBOOK}>
                  <PeopleCount>
                    {' '}
                    {formatSocialUsersCount(socialInfo.facebook)}
                  </PeopleCount>
                  <CommunityImage src={Facebook} />
                </CommunityBox>
                <CommunityBox target="_blank" href={SocialLinks.INSTAGRAM}>
                  <CommunityImage src={Instagram} />
                </CommunityBox>
                <CommunityBox target="_blank" href={SocialLinks.SNAPCHAT}>
                  <CommunityImage src={Snapchat} />
                </CommunityBox>
              </CommunityBoxes>
              <CommunityBoxes>
                <CommunityBox target="_blank" href={SocialLinks.MEDIUM}>
                  <CommunityImage src={Medium} />
                </CommunityBox>
                <CommunityBox target="_blank" href={SocialLinks.LINE}>
                  <CommunityImage src={Line} />
                </CommunityBox>
              </CommunityBoxes>
            </CommunityWrapper>
          )}
          <BurgerMenuImage src={RightArrow} />
        </BurgerMenu>
      )}
    </Container>
  );
};

export default Header;
