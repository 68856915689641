import React from 'react';
import { navigate } from 'gatsby';
import * as styles from './styled';

export enum ButtonStyleTypes {
  DEFAULT = 'default',
  INVERTED = 'inverted',
}

export enum BorderType {
  BORDER_RADIUS = 'borderRadius',
  NO_BORDER_RADIUS = 'noBorderRadius',
}

interface IButton {
  styleType: ButtonStyleTypes;
  width?: string;
  link?: string;
  onClick?: () => void;
  children: any;
  disabled?: boolean;
  borderType?: BorderType;
  height?: string;
}

export const Button: React.FC<IButton> = ({
  children,
  styleType = ButtonStyleTypes.DEFAULT,
  width = '100px',
  onClick,
  link,
  disabled = false,
  borderType = BorderType.BORDER_RADIUS,
  height,
}) => {
  const _onClick = () => {
    if (onClick) {
      onClick();
    }
    if (link) {
      navigate(link);
    }
  };

  return (
    <styles.Button
      disabled={disabled}
      onClick={_onClick}
      width={width}
      styleType={styleType}
      borderType={borderType}
      height={height}
    >
      {children}
    </styles.Button>
  );
};
