const numberWithCommas = (value: number) => {
  const strValue = value.toString();
  return `${strValue.replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1,')}`;
};

const formatSocialUsersCount = (value) => {
  if (value < 1000) {
    return value;
  }

  return `${Math.round(value / 1000)}k`;
};

export { numberWithCommas, formatSocialUsersCount };
