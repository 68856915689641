import styled from 'styled-components';
import CardImage from '@/images/Cart/CardImage.png';
import PlusBtnImage from '@/images/nftsIntro/Add.svg';
import HideBtnImage from '@/images/nftsIntro/Hide.svg';
import BucketBtnImage from '@/images/nftsIntro/Bucket.svg';

export const Card = styled.div<{ checkoutStyles: boolean }>`
  display: flex;
  background: ${(props) =>
    !props.checkoutStyles
      ? 'linear-gradient(#0a0c13, #0a0c13) padding-box, linear-gradient(180deg, #486dd3 0%, #0d1018 100%) border-box'
      : 'none'};
  width: ${(props) => (props.checkoutStyles ? '100%' : '575px')};
  border: 1px solid transparent;
  height: ${(props) => (props.checkoutStyles ? '100px' : '145px')};
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: ${(props) => (!props.checkoutStyles ? '15px 20px 15px 15px' : '0')};
  margin-top: ${(props) => (props.checkoutStyles ? 20 : 15)}px;
  &:first-child {
    margin-top: 0;
  }
  @media (max-width: 767px) {
    max-width: ${(props) => (props.checkoutStyles ? '100%' : '370px')};
    padding: ${(props) =>
      !props.checkoutStyles ? '15px 15px 15px 15px' : '0'};
  }
  @media (max-width: 410px) {
    max-width: ${(props) => (props.checkoutStyles ? '90%' : '350px')};
    padding: ${(props) =>
      !props.checkoutStyles ? '15px 15px 15px 15px' : '0'};
  }
`;
export const CardContent = styled.div`
  width: 100%;
  padding-right: 10px;
  @media (max-width: 767px) {
    width: 64%;
  }
`;
export const ImageBox = styled.div<{ checkoutStyles: boolean; cardImage: any }>`
  max-width: ${(props) => (props.checkoutStyles ? 100 : 127)}px;
  width: 100%;
  border-radius: 5px;
  margin-right: 15px;
  height: 100%;
  background: url(${(props) => (props.cardImage ? props.cardImage : CardImage)})
    center;
  @media (max-width: 767px) {
    max-width: 95px;
  }
`;
export const CardHeader = styled.div<{ checkoutStyles: boolean }>`
  font-size: ${(props) =>
    props.checkoutStyles ? 'calc(15px + 5 * (100vw - 420px) / 880)' : '30px'};
  font-weight: 400;
  line-height: ${(props) => (props.checkoutStyles ? 24 : 28)}px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 767px) {
    font-size: calc(18px + 5 * (100vw - 420px) / 880);
    width: 210px;
  }
  @media (min-width: 1230px) {
    font-size: 23.5px;
  }
`;

export const ViText = styled.span<{ isVip: boolean; checkoutStyles: boolean }>`
  color: ${(props) => props.theme.colors.gold};
  font-size: ${(props) => (props.checkoutStyles ? 20 : 30)}px;
  font-weight: 400;
  line-height: ${(props) => (props.checkoutStyles ? 24 : 36)}px;
  letter-spacing: 0px;
  text-align: left;
  display: ${(props) => (props.isVip ? 'inline' : 'none')};
  @media (max-width: 767px) {
    font-size: 24px;
    margin-right: 5px;
    line-height: 28.8px;
  }
`;

export const CostPerUnit = styled.span<{ checkoutStyles: boolean }>`
  font-size: 15px;
  font-weight: ${(props) => (props.checkoutStyles ? '400' : '600')};
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: ${(props) => props.theme.colors.blue};
`;
export const UnitText = styled.span<{ checkoutStyles: boolean }>`
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  ${(props) => {
    if (props.checkoutStyles) {
      return ' text-transform: lowercase';
    }
    return null;
  }};
  ${(props) => {
    if (props.checkoutStyles) {
      return `color: ${props.theme.colors.gray}`;
    }
    return null;
  }};
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
`;
export const BtnBox = styled.div`
  display: flex;
  align-items: center;
`;
export const PlusBtn = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid ${(props) => props.theme.colors.pink};
  border-radius: 3px;
  background: url(${PlusBtnImage}) no-repeat center;
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    filter: brightness(1.5);
  }
`;
export const Count = styled.span``;
export const HideBtn = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid ${(props) => props.theme.colors.pink};
  border-radius: 3px;
  background: url(${HideBtnImage}) no-repeat center;
  margin-right: 10px;
  cursor: pointer;
  &:hover {
    filter: brightness(1.5);
  }
`;
export const CostBox = styled.div<{ checkoutStyles: boolean }>`
  display: flex;
  align-items: ${(props) => (props.checkoutStyles ? 'center' : 'flex-end')};
  @media (max-width: 767px) {
    align-items: center;
  }
`;
export const Cost = styled.div<{ checkoutStyles: boolean }>`
  font-size: ${(props) => (props.checkoutStyles ? 15 : 20)}px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: right;
`;
export const Currency = styled.span<{ checkoutStyles: boolean }>`
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: right;
  margin-left: ${(props) => (props.checkoutStyles ? 5 : 11)}px;
  ${(props) => {
    if (props.checkoutStyles) {
      return `color: ${props.theme.colors.gray}`;
    }
    return null;
  }};
  @media (max-width: 767px) {
    font-size: 15px;
  }
`;

export const RemoveBtn = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid ${(props) => props.theme.colors.blue};
  border-radius: 3px;
  background: url(${BucketBtnImage}) no-repeat center;
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    filter: brightness(1.5);
  }
`;
