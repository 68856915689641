import styled from 'styled-components';

export const AlertContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > p {
    text-transform: initial;
    font-size: 16px;
  }
`;
