import React, { FC } from 'react';
import {
  BtnBox,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  Cost,
  CostBox,
  CostPerUnit,
  Count,
  Currency,
  HideBtn,
  ImageBox,
  PlusBtn,
  UnitText,
  ViText,
  RemoveBtn,
} from '@/components/CartCard/CartCard.styled';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { removeFromCart, setCount } from '@/Store/actions/cart';
import { TokenTypes } from '@/types/products';
import { getImageByProduct } from '@/utils/productimages';

interface ICartCardProps {
  checkoutStyles: boolean;
  isVip?: boolean;
  costPerUnit: number;
  name: string;
  productId: number;
  count?: number;
  productVariants?: any;
  showRemoveButton?: boolean;
  hasCountControlls?: boolean;
}

const CartCard: FC<ICartCardProps> = ({
  checkoutStyles,
  costPerUnit,
  isVip,
  name,
  productId,
  count = 1,
  productVariants,
  showRemoveButton = false,
  hasCountControlls = true,
}) => {
  const { productsInCart } = useAppSelector((state) => state.cartReducer);
  const dispatch = useAppDispatch();

  const product = productsInCart.find((product) => product?.id === productId);

  const addItem = () => {
    dispatch(setCount(productId, product?.count ? product?.count + 1 : 2));
  };

  const removeItem = () => {
    dispatch(setCount(productId, product?.count && (product?.count - 1 || 1)));
  };

  const onRemoveItem = () => {
    dispatch(removeFromCart(productId));
  };

  const isToken = product.type === TokenTypes.TOKEN;

  return (
    <Card checkoutStyles={checkoutStyles}>
      <ImageBox
        checkoutStyles={checkoutStyles}
        cardImage={getImageByProduct(product, '127_115')}
      />
      <CardContent>
        <CardHeader checkoutStyles={checkoutStyles}>
          <div>
            <ViText isVip={isVip} checkoutStyles={checkoutStyles}>
              VIP
            </ViText>
            {name.replace('VIP', '')}
          </div>
          {showRemoveButton && <RemoveBtn onClick={onRemoveItem} />}
        </CardHeader>
        <CostPerUnit checkoutStyles={checkoutStyles}>
          {costPerUnit} {'$ '}
          <UnitText checkoutStyles={checkoutStyles}>PER UNIT</UnitText>
        </CostPerUnit>
        <CardFooter>
          <BtnBox>
            {!isToken && (
              <>
                {hasCountControlls && <HideBtn onClick={removeItem} />}
                <Count>{count || 1}</Count>
                {hasCountControlls && <PlusBtn onClick={addItem} />}
              </>
            )}
            {isToken && (
              <>
                {' '}
                <Count>{count || 200}</Count>
              </>
            )}
          </BtnBox>

          <CostBox checkoutStyles={checkoutStyles}>
            <Cost checkoutStyles={checkoutStyles}>
              {((count || 1) * productVariants?.[0]?.price).toFixed(2)}
            </Cost>
            <Currency checkoutStyles={checkoutStyles}>$</Currency>
          </CostBox>
        </CardFooter>
      </CardContent>
    </Card>
  );
};

export default CartCard;
