export enum TokenTypes {
  NFT = 'erc721',
  TOKEN = 'erc20_presale',
}

export interface IVariant {
  id: number;
  title: string;
  enabled: boolean;
  price: number;
  quantity_total: number;
  quantity_left: number;
  quantity_sold: number;
}

export interface IProduct {
  id: number;
  title: string;
  variants: IVariant[];
  count?: number;
  type: TokenTypes;
}
