import styled from 'styled-components';
import { ButtonStyleTypes, BorderType } from '.';

interface IButton {
  styleType: ButtonStyleTypes;
  borderType: BorderType;
  width?: string;
  height?: string;
}

export const Button = styled.button<IButton>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 0 16px 0px;
  border-radius: 5px;
  font-size: 15px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.white};
  ${(props) => {
    if (props.width) {
      return `
        width: ${props.width};
      `;
    }
  }}

  ${(props) => {
    if (props.height) {
      return `
        max-height: ${props.height};
      `;
    }
  }}

  ${(props) => {
    if (props.borderType === BorderType.NO_BORDER_RADIUS) {
      return `
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      `;
    }
    return `
      border-radius: 5px;
    `;
  }}

  ${(props) => {
    if (props.styleType === ButtonStyleTypes.DEFAULT) {
      return `
        border: 1px solid ${props.theme.colors.pink};
        background: ${props.theme.colors.pink};
      `;
    }
    return `
        border: 1px solid ${props.theme.colors.white};
        background: transparent;
      `;
  }}

  &:hover {
    ${(props) => {
      if (props.styleType === ButtonStyleTypes.DEFAULT) {
        return `
          border: 1px solid ${props.theme.colors.pink};
          box-shadow: 0px 10px 40px rgba(199, 45, 146, 0.3);
          font-size: 16px;
          filter: brightness(1.15);
        `;
      }
      return `
          border: 1px solid ${props.theme.colors.pink};
          background: transparent;
          color: ${props.theme.colors.pink};
          font-size: 16px;
          filter: brightness(1.15);
        `;
    }}
  }

  &:selected {
    ${(props) => {
      if (props.styleType === ButtonStyleTypes.DEFAULT) {
        return `
          background: #9f1b72;
        `;
      }
    }}
  }

  &:disabled {
    &:hover {
      box-shadow: none;
    }

    ${(props) => {
      if (props.styleType === ButtonStyleTypes.DEFAULT) {
        return `
          background: #798094;
          border: 1px solid #798094;
        `;
      }
      return `
          color: #798094;
          border: 1px solid #798094;
        `;
    }}
  }
`;
